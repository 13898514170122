import {KD, List, log} from './kd';
import raw from 'raw.macro';
import { Quantity } from './kd/Quantity';
import firebase from 'firebase';
import {Gradient} from "./paint/Gradient";
import {PaintBox} from "./paint/PaintBox";

export class Config {
  static loadConfig() {
    Quantity.registerUnits('vh', 'vw', 'em', 'rem', 'px', 'deg');

    let text = raw('./paint/paints.kd');
    try {
      let root = KD.eval(text);
      log(root);
      PaintBox.init(root);
    } catch (e: any) {
      console.log(e);
    }

    /*
    let text = raw('./themes/basic.kd');
    try {
      let root = KD.eval(text);
      // let backgroundTag = root.getChild('background');
      log(root);
    } catch (e: any) {
      console.log(e);
    }
    */

    try {
      this.initializeFirebase();
    } catch (error) {
      console.error('firebase initialization error', error);
    }
  }

  private static initializeFirebase() {
    firebase.initializeApp({
      apiKey: 'AIzaSyBfDpfBSThFOtTErN3sIx1iyP8fUr_lnJ0',
      authDomain: 'pixki-ea21b.firebaseapp.com',
      databaseURL: 'https://pixki-ea21b.firebaseio.com',
      projectId: 'pixki-ea21b',
      storageBucket: 'pixki-ea21b.appspot.com',
      messagingSenderId: '1020529009775',
      appId: '1:1020529009775:web:e3e3011993b66e7bcfd99a',
      measurementId: 'G-BHKFXCYP87',
    });

    const db = firebase.firestore();
    if (window.location.hostname === 'localhost') {
      db.useEmulator('localhost', 8080);
    }
  }
}
