import { useEffect, useRef } from 'react';
import './Option.scss';
import 'react-bulma-components/lib/components/image';
import useWindowSize from 'hooks/useDimension';
import useMobileDevice from 'hooks/useMobileDevice';
import {Paint} from "../../../paint/Paint";

export enum MediaType {
  Video = 'video',
  Image = 'image',
  Text = 'text',
}

export interface Option {
  id: number;
  media: string;
  type: MediaType;
  caption: string;
  subcaption: string;

  // For MediaType.Text
  text?: string;
  background?: Paint;
}

type OptionProps = { option: Option; trackRef?: boolean; notifyHeightUpdate?; selectOption?: Function };

const TournletOption = ({ option, trackRef, notifyHeightUpdate, selectOption }: OptionProps) => {
  const mediaRef: any = useRef();
  const size = useWindowSize();
  const isMobile = useMobileDevice();

  const getMediaElement = () => {
    switch (option.type) {
      case MediaType.Image:
        return <img className="has-ratio" src={option.media} alt="" />;
      case MediaType.Video:
        return (
          <video key={option.id} className="has-ratio" loop muted autoPlay>
            <source src={option.media}></source>
          </video>
        );
      case MediaType.Text:
        return <p className="has-ratio">{option.media}</p>;
    }
  };

  useEffect(() => {
    if (trackRef && notifyHeightUpdate) {
      notifyHeightUpdate(mediaRef.current.clientHeight);
    }
  }, [size?.height, size?.width, notifyHeightUpdate, trackRef]);

  const hasCaptions = () => {
    return !!option.caption || !!option.subcaption;
  };

  return (
    <button className="Option" onClick={() => selectOption && selectOption(option)}>
      <div className={`image media option-img is-square ${!isMobile && 'hoverable'}`} ref={mediaRef}>
        {getMediaElement()}
      </div>
      {hasCaptions() && (
        <div className="captions">
          {!!option.caption && <p className="caption">{option.caption}</p>}
          {!!option.subcaption && <p className="subcaption">{option.subcaption}</p>}
        </div>
      )}
    </button>
  );
};

export default TournletOption;
