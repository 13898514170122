import './Tournlet.scss';
import { Frame } from 'components/Frames/Frames';
import Match from 'components/Match/Match';
import * as React from 'react';
import { useCallback, useEffect, useReducer } from 'react';
import { Option } from '../Match/Option/Option';
import { reducer, TournletState, ActionType } from './reducer';
import { shuffle } from 'utils/array';
import { User } from 'models/user';
import TournletCover from './TournletCover/TournletCover';
import TournletCloser from './TournletCloser/TournletCloser';

export interface TournletOptionStat {
  optionId: number;
  position: number;
  percentage: number;
}

export interface TournletData {
  author: User;
  coverImage: string;
  creationDate: string;
  description?: string;
  entrants: number;
  frames?: Frame[];
  id: string;
  likes: number;
  options: Option[];
  plays: number;
  slug: string;
  title: string;
  optionStats: TournletOptionStat[];
}

type TournletProps = {
  styles?: { tournlet: React.CSSProperties; title: React.CSSProperties };
  data: TournletData;
};

const defaultStyles = {
  tournlet: { backgroundColor: 'rgb(226, 245, 228)' },
  title: { color: 'rgb(72, 122, 85)', fontFamily: 'Grandstander', fontWeight: 500 },
};

const initialState = (): TournletState => {
  return { currentRound: 0, nextRoundOptions: [], roundOptions: [], playInRounds: 0, winner: null, showCover: true };
};

/**
 * Tournlet component, this is the main component that will contain
 * most of the app interaction at this stage
 */
const Tournlet = ({ styles = defaultStyles, data }: TournletProps) => {
  const [state, dispatch] = useReducer(reducer, {}, initialState);
  // const options: Option[] = useOptions(data.id);

  useEffect(() => dispatch({ type: ActionType.COVER }), []);

  const isPlayInRound = () => state.playInRounds > 0;

  const getTotalRounds = () =>
    isPlayInRound() ? state.playInRounds : state.roundOptions.length / 2 + state.nextRoundOptions.length;

  const initTournlet = useCallback(() => {
    if (data) {
      const payload = prepareInitPayload(data);
      dispatch({ type: ActionType.INIT, payload });
    }
  }, [data]);

  const selectOption = (option: Option) => {
    const selectedOption = state.roundOptions.find(ro => ro.id === option.id);
    if (selectedOption) {
      const remainingOptions = state.roundOptions.slice(2);

      if (remainingOptions.length >= 2) {
        dispatch({
          type: ActionType.NEXT_ROUND,
          payload: { roundOptions: remainingOptions, nextRoundOptions: [...state.nextRoundOptions, selectedOption] },
        });
      } else {
        // no more rounds on stage, move to next stage
        if (!state.nextRoundOptions.length && !remainingOptions.length) {
          // no more stages, we have a winner
          dispatch({ type: ActionType.SET_WINNER, payload: { winner: option } });
        } else {
          dispatch({
            type: ActionType.NEXT_STAGE,
            payload: { roundOptions: shuffle([...remainingOptions, ...state.nextRoundOptions, option]) },
          });
        }
      }
    }
  };

  if (state.showCover) {
    return (
      <div className="Tournlet">
        <TournletCover tournletData={data} startPlaying={() => initTournlet()} />
      </div>
    );
  }

  return (
    <div className="Tournlet">
      {!state.winner ? (
        <>
          <div className="tournlet-container">
            <Match
              options={state?.roundOptions?.slice(0, 2)}
              stage={{ currentRound: state.currentRound, totalRounds: getTotalRounds(), isPlayIn: isPlayInRound() }}
              selectOption={selectOption}
            />
          </div>
        </>
      ) : (
        <TournletCloser tournletData={data} winner={state.winner} />
      )}
    </div>
  );
};

function prepareInitPayload(data: TournletData): TournletState {
  const payload: TournletState = {};
  if (!isPowerOfTwo(data.options.length)) {
    // seeding round needed
    const neededRounds = data.options.length - Math.pow(2, Math.floor(Math.log2(data.options.length)));
    payload.playInRounds = neededRounds;
    payload.roundOptions = data.options.slice(0, neededRounds * 2);
    payload.nextRoundOptions = data.options.slice(neededRounds * 2);
  } else {
    payload.nextRoundOptions = [];
    payload.roundOptions = data.options;
  }
  payload.roundOptions = shuffle(payload.roundOptions);

  return payload;
}

function isPowerOfTwo(value: number): boolean {
  return (value & (value - 1)) === 0;
}

export default Tournlet;
