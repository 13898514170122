import {Paint} from "./Paint";
import {List, log, Tag} from "../kd";
import {Color} from "./Color";
import {ParseError} from "../kd/ParseError";
import {Gradient} from "./Gradient";
import {NSID} from "../kd/NSID";
import {Call} from "../kd/Call";
import {PImage} from "./PImage";

export class PaintBox {

    private static paints = new Map<string, Paint>();

    static set(name: string, paint: Paint) {
        this.paints.set(name, paint);
    }

    static get(name: string): Paint | null {
        return this.paints.get(name) ?? null;
    }

    static getPaints() {
        return this.paints.entries();
    }

    static stringifyColor(color: unknown): string {
        if(typeof color === 'string') {
            return color;
        } else if(typeof color === 'number') {
            return `#${color.toString(16)}`;
        } else if(color && typeof color === 'object' && 'toString' in color) {
            return `${color.toString()}`;
        }

        throw new ParseError("Unknown color type: " + color);
    }

    static init(config: Tag) {
        for(let child of config.children) {
            if(child.values.length === 1) {
                // color or image
                let value1 = child.value()
                if(value1 && value1 as Call && (value1 as Call).name == "image") {
                    this.setImagePaint(value1 as Call, child);
                } else {
                    let color = this.stringifyColor(child.value());
                    this.set(child.name, new Color(color));
                }
            } else {
                // gradient

                let degrees = child.getAttribute("degrees") ?? 0;
                let colors = new List<string>();

                for(let value of child.values) {
                    if(typeof value === "string") {
                        let paint = this.get(value);
                        if(paint && paint as Color) {
                            // we have a color
                            colors.add(paint.toString());
                            continue;
                        }
                    }

                    colors.add(this.stringifyColor(value));
                }
                this.set(child.name, new Gradient(colors, degrees));
            }
        }

        for (const [name, paint] of this.paints) {
            log(`${name}.css = ${paint.getCSSRule()}`);
        }
    }

    private static setImagePaint(value: Call, tag: Tag) {
        if(!value.value() || !(value.value() as string)) {
            throw Error("Image paint types must have an imageName");
        }

        let imageName = value.value() as string;

        if(tag.attributes.size === 0) {
            this.set(tag.name, new PImage(imageName));
        } else {
            let tiled = tag.getAttribute("tiled");
            let width = tag.getAttribute("width");
            let height = tag.getAttribute("height");
            this.set(tag.name, new PImage(imageName, tiled, width, height));
        }
    }


    static applyPaint(paintName: string, className: string) {
        let sheet = document.styleSheets[0];
        let paint = this.get(paintName);
        if(!paint)
            throw new Error(`Paint "${paintName}" does not exist.`)
        let rule1 = `.${className} {${paint!.getCSSRule()}}`;
        sheet.insertRule(rule1, sheet.cssRules.length);
    }
}