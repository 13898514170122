import {Paint} from "./Paint";
import {List} from "../kd";

/**
 * A simple two stop gradient with rotation
 */
export class Gradient implements Paint {
    colors: List<string>;
    degrees: number;

    constructor(colors: List<string>, degrees = 0) {
        this.colors = colors;
        this.degrees = degrees;
    }

    getCSSRule(): string {
        let colors = '';
        this.colors.forEach(color => colors+=(', ' + color));
        return `background-image: linear-gradient(${this.degrees}deg${colors});`;
    }

    toString(): string {
        return `linear-gradient(${this.degrees}deg, ${this.colors.stringify(", ")});`;
    }
}