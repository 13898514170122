import './UserBadge.scss';
import { User } from 'models/user';

type UserBadgeProps = {
  user: User;
};

const UserBadge = ({ user }: UserBadgeProps) => {
  return (
    <div className="UserBadge">
      <img src={user.profileImg} alt="" />
      <div className="created-by">
        <span className="caption">{user.username}</span>
        <a className="caption" href="">
          {user.tournlets} Tournlets
        </a>
      </div>
    </div>
  );
};

export default UserBadge;
