import { Option } from '../Match/Option/Option';



export enum ActionType {
  INIT = 'TOURNLET_INIT',
  COVER = 'TOURNLET_COVER',
  NEXT_ROUND = 'NEXT_ROUND',
  NEXT_STAGE = 'NEXT_STAGE',
  SET_WINNER = 'SET_WINNER',
}

export interface TournletAction {
  payload?: TournletState;
  type: ActionType;
}

export interface TournletState {
  currentRound?: number;
  nextRoundOptions?: Option[];
  roundOptions?: Option[];
  playInRounds?: number;
  winner?: Option | null;
  showCover?: boolean;
}

export const reducer = (state, action: TournletAction) => {
  switch (action.type) {
    case ActionType.COVER:
      return { ...state, showCover: true };
    case ActionType.INIT:
      return { ...state, ...action.payload, winner: null, currentRound: 1, showCover: false };
    case ActionType.NEXT_ROUND:
      return {
        ...state,
        ...action.payload,
        currentRound: state.currentRound + 1,
      };
    case ActionType.SET_WINNER:
      return {
        ...state,
        winner: action?.payload?.winner,
      };
    case ActionType.NEXT_STAGE:
      return {
        ...state,
        playInRounds: 0,
        nextRoundOptions: [],
        currentRound: 1,
        ...action.payload,
      };
  }
};
