import './Header.scss';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks/auth/useAuth';
import { loadSeed } from 'services/seed';

export const Header = () => {
  const auth: any = useAuth();

  const signOut = () => auth.signOut();

  return (
    <header className="Header">
      <nav>
        <ul>
          <li>
            {auth.user ? (
              <>
                <button onClick={() => signOut()} className="light-link">
                  Sign Out
                </button>
                <button className="light-link" onClick={() => loadSeed()}>
                  Load Seed
                </button>
              </>
            ) : (
              <Link to="/auth" className="light-link">
                Sign In
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
