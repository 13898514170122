import React, { useState, useEffect, useContext, createContext } from 'react';
import firebase from 'firebase';
import 'firebase/auth';
import useMobileDevice from 'hooks/useMobileDevice';

const authContext = createContext({});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState<null | firebase.User>(null);
  const isMobile = useMobileDevice();

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signIn = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const signUp = (email, password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        response?.user?.sendEmailVerification();
        setUser(response.user);
        return response.user;
      });
  };

  const signOut = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      });
  };

  const sendPasswordResetEmail = email => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const facebookAuth = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return socialAuth(provider);
  };

  const googleAuth = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return socialAuth(provider);
  };

  const twitterAuth = async () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return socialAuth(provider);
  };

  const socialAuth = async (provider: firebase.auth.AuthProvider) => {
    let response;
    try {
      if (isMobile) {
        firebase.auth().signInWithRedirect(provider);
        response = await firebase.auth().getRedirectResult();
      } else {
        await firebase.auth().signInWithPopup(provider);
      }

      setUser(response.user);
      return response.user;
    } catch (error) {
      console.error(error.message);
    }
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signIn: signIn,
    signUp: signUp,
    signOut: signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    facebookAuth,
    googleAuth,
    twitterAuth,
  };
}
