import { useState, useEffect } from 'react';

function useMobileDevice() {
  const [mobileDevice, setMobileDevice] = useState<boolean>(false);

  useEffect(() => {
    setMobileDevice(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  }, []);

  return mobileDevice;
}

export default useMobileDevice;
