import * as React from 'react';
import { useState } from 'react';
import './Match.scss';
import TournletOption, { Option } from './Option/Option';

export type Stage = {
  currentRound: number;
  isPlayIn: boolean;
  totalRounds: number;
};

type MatchProps = { options?: Option[]; styles?: any; stage?: Stage; selectOption: Function };

const Match = ({ options = [], styles, stage, selectOption }: MatchProps) => {
  const [left, right] = options;
  const [vsTop, setVsTop] = useState<string>();

  const updateVsLabel = (imageSize: number) => setVsTop(`${imageSize / 2}px`);

  return options.length === 2 ? (
    <div className="Match">
      <TournletOption option={left} trackRef={true} notifyHeightUpdate={updateVsLabel} selectOption={selectOption} />
      <div className="vs-match-info" style={{ top: vsTop }}>
        <div className="vs-label">VS</div>
      </div>
      <TournletOption option={right} selectOption={selectOption} />
    </div>
  ) : (
    <></>
  );
};

export default Match;
