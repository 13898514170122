import './TournletCloser.scss';
import UserBadge from 'components/shared/UserBadge/UserBadge';
import { TournletData, TournletOptionStat } from '../Tournlet';
import { MediaType, Option } from '../../Match/Option/Option';
import React, { useCallback } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';
import { ReactComponent as PlayIcon } from '../../../assets/icons/play-icon.svg';

type TournletCloserProps = {
  tournletData: TournletData;
  winner: Option;
};

interface RankingOption {
  option: Option;
  stat: TournletOptionStat;
}

function getRanking(tournletData: TournletData): RankingOption[] {
  return tournletData.optionStats.map((os: TournletOptionStat) => ({
    option: tournletData.options.find((o: Option) => o.id === os.optionId)!,
    stat: os,
  }));
}

const TournletCloser = ({ tournletData, winner }: TournletCloserProps) => {
  const renderMedia = (option: Option) => {
    switch (option.type) {
      case MediaType.Image:
        return <img className="media" src={option.media} alt="" />;
      case MediaType.Video:
        return (
          <video className="media" loop muted autoPlay>
            <source src={option.media} type="video/webm"></source>
          </video>
        );
      case MediaType.Text:
        return (
          <LinesEllipsis className="media" text={option.media} maxLine="5" ellipsis="..." trimRight basedOn="letters" />
        );
      default:
        return <></>;
    }
  };

  const getRankingItem = (ro: RankingOption, index: number) => {
    return (
      <div className="ranking-item">
        {renderMedia(ro.option)}
        <span className={`option-caption subtitle-2`}>
          {ro.stat.position}. {ro.option.caption} ({ro.stat.percentage}%)
        </span>
      </div>
    );
  };

  const winnerRanking = useCallback(() => {
    return (
      <ol className="option-ranking">
        {getRanking(tournletData).map((ro: RankingOption, index: number) => (
          <li className={`${ro.option.id === winner.id ? 'winner' : ''}`} key={index}>
            {getRankingItem(ro, index)}
          </li>
        ))}
      </ol>
    );
  }, [getRankingItem]);
  
  return (
    <div className="TournletCloser two-col ">
      <div className="left">
        <h1 className="headline-2 title">{tournletData.title}</h1>
        <UserBadge user={tournletData.author} />
        <div className="suggested-tournlets">
          <h2 className="subtitle-1 suggested-title">Suggested Tournlets</h2>
          <ul className="caption">
            <li>
              <PlayIcon className="play-btn" /> <Link to="/">Bond Villains</Link>
            </li>
            <li>
              <PlayIcon className="play-btn" /> <Link to="/">Kisses in Art</Link>
            </li>
            <li>
              <PlayIcon className="play-btn" /> <Link to="/">2024 Presidential Hopefuls</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="right">{winnerRanking()}</div>
    </div>
  );
};

export default TournletCloser;
