import { ReactComponent as ShareIcon } from 'assets/icons/share-icon.svg';
import React from 'react';

const ShareButton = () => {
  return (
    <button className="light-icon">
      <ShareIcon />
    </button>
  );
};

export default ShareButton;
