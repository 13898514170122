import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth/useAuth';
import './Auth.scss';
import SocialAuthButton from './SocialAuthButton/SocialAuthButton';

export const Auth = () => {
  const [email, setEmail] = useState<null | string>(null);
  const [password, setPassword] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const auth: any = useAuth();

  const [showSignUp, setShowSignUp] = useState(false);

  let history = useHistory();

  const authUser = async action => {
    if (!loading) {
      setLoading(true);

      try {
        switch (action) {
          case 'signUp':
            await auth.signUp(email, password);
            break;
          case 'signIn':
            await auth.signIn(email, password);
            break;
          case 'facebook':
            await auth.facebookAuth();
            break;
          case 'twitter':
            await auth.twitterAuth();
            break;
          case 'google':
            await auth.googleAuth();
            break;
          default:
            break;
        }
        history.replace('/');
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="Auth">
      <div className="content">
        <h1>{showSignUp ? 'Sign Up' : 'Sign In'}</h1>
        <SocialAuthButton service="facebook" onClick={() => authUser('facebook')} />
        <SocialAuthButton service="google" onClick={() => authUser('google')} />
        <SocialAuthButton service="twitter" onClick={() => authUser('twitter')} />

        <div className="or-divider">
          <hr />
          or
          <hr />
        </div>

        <form>
          <label htmlFor="email">Email</label>
          <input
            name="email"
            id="email"
            type="email"
            autoComplete="email"
            required
            autoFocus
            onChange={event => setEmail(event.target.value)}
          />

          <label htmlFor="password">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="password"
            required
            onChange={event => setPassword(event.target.value)}
          />

          {showSignUp ? (
            <>
              <button className="alt-btn" type="button" onClick={() => authUser('signUp')}>
                {loading ? 'Creating your account...' : 'Sign up'}
              </button>
              <button className="link" onClick={() => setShowSignUp(false)}>
                Already have an account? Sign in
              </button>
              <p className="terms">
                By creating an account, you agree to our{' '}
                <a
                  href="https://docs.google.com/document/d/1u_YscTuxo8bR9nhAVZ13NhFb3tXJ_i8u4bzFKGpjP9E/edit?usp=sharing"
                  target="blank"
                  className="link"
                >
                  User Agreement and Privacy Policy.
                </a>
              </p>
            </>
          ) : (
            <>
              <button className="alt-btn" type="button" onClick={() => authUser('signIn')}>
                {loading ? 'Signing in...' : 'Continue'}
              </button>
              <button className="link" onClick={() => setShowSignUp(true)}>
                Don't have an account? Sign up
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};
