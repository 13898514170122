import './Game.scss';
import { ForceLandscape } from 'components/ForceLandscape/ForceLandscape';
import SocialBar from 'components/SocialBar/SocialBar';
import Tournlet, { TournletData } from 'components/Tournlet/Tournlet';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getTournletBySlug } from 'services/tournlets.service';

export type GameProps = {
  tournletId?: number;
  username?: string;
  slug?: string;
};

const Game = (props: GameProps) => {
  const [tournletData, setTournletData] = useState<TournletData | null>(null);

  const params = useParams<any>();
  const history = useHistory();

  const init = useCallback(async () => {
    try {
      setTournletData(await getTournletBySlug(params.username!, params.slug!));
    } catch (error) {
      alert(error);
      history.goBack();
    }
  }, [params.username, params.slug, history]);

  useEffect(() => {
    try {
      init();
    } catch (error) {
      console.error(error);
    }
  }, [params.username, params.slug, init]);

  return (
    <div className="Game">
      {tournletData && <Tournlet data={tournletData} />}
      <SocialBar />
      <ForceLandscape />
    </div>
  );
};

export default Game;
