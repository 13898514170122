import { TournletData } from 'components/Tournlet/Tournlet';
import { extractQuery, getFirestoreInstance } from 'utils/firebase.utils';
import { DateTime } from 'luxon';
import firebase from 'firebase/app';

export const getTournletBySlug = async (username: string, slug: string): Promise<TournletData> => {
  const db = getFirestoreInstance();
  const tournletsQuery = await db
    .collection('tournlets')
    .where('author.username', '==', username)
    .where('slug', '==', slug)
    .limit(1)
    .get();

  const tournlet = extractQuery(tournletsQuery)[0];
  const optionsQuery = await db.collection(`tournlets/${tournlet.id}/options`).get();
  const options = extractQuery(optionsQuery);

  return { ...tournlet, options };
};

export const getStageName = (matches: number | any): string => {
  switch (matches) {
    case 8:
      return 'Eighth-finals';
    case 4:
      return 'Quarterfinals';
    case 2:
      return 'Semifinals';
    case 1:
      return 'Final';
    default:
      return '';
  }
};

const toKebabCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');

const uploadFile = async (file: File, path: string) => {
  const storageRef = firebase.storage().ref();
  const imageRef = storageRef.child(`${path}/${file.name}`);
  const uploadRes = await imageRef.put(file);
  return uploadRes.ref.getDownloadURL();
};

export const createTournlet = async (tournletData, options, mediaFiles) => {
  const db = getFirestoreInstance();
  const tournletSlug = toKebabCase(tournletData.title);

  const coverImageUrl = await uploadFile(mediaFiles.cover, tournletSlug);

  let tournletRef: any = await db
    .collection('tournlets')
    .doc(tournletSlug)
    .set({
      title: tournletData.title,
      coverImage: coverImageUrl,
      slug: tournletSlug,
      creationDate: DateTime.utc().toString(),
      likes: 0,
      plays: 0,
      description: tournletData.description || '',
      author: {
        id: 1,
        username: 'gmorris',
        profileImg:
          'https://firebasestorage.googleapis.com/v0/b/pixki-ea21b.appspot.com/o/shared%2Fuser.svg?alt=media&token=b92946bf-6ae8-4abd-b897-c9d9ba394d7e',
        tournlets: 4,
      },
    });


  const optionFiles = {...mediaFiles};
  delete optionFiles.cover;
  
  const optionUrls = await Promise.all(
    Object.keys(optionFiles).map(async mfk => {
      const fileUrl = await uploadFile(optionFiles[mfk], tournletSlug);
      return { [mfk]: fileUrl };
    }),
  );

  const optionIds: any[] = [];

  await Promise.all(
    options.map(async (o, index) => {
      const optionRef = await db
        .collection('tournlets')
        .doc(toKebabCase(tournletData.title))
        .collection('options')
        .add({...o, media: optionUrls[index][index]});
      optionIds.push(optionRef.id);
    }),
  );

  await db.collection('tournlets')
    .doc(toKebabCase(tournletData.title))
    .set(
      {
        optionStats: optionIds.map((id, index) => ({
          optionId: id,
          position: index + 1,
          percentage: Math.max(100 - index * 10, 0),
        })),
      },
      { merge: true },
    );
    
  return `${window.location.hostname}/gmorris/${tournletSlug}`;
};
