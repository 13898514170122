import * as React from 'react';
import './App.scss';

import useWindowSize from 'hooks/useDimension';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import routes from './routes';

import { ProvideAuth } from 'hooks/auth/useAuth';

function App() {
  const windowSize = useWindowSize();

  return (
    <ProvideAuth>
      <Router>
        <div className="App" style={{ height: windowSize.height }}>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

export default App;
