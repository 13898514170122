import { useState } from 'react';
import './AdminPage.scss';
import { createTournlet } from 'services/tournlets.service';

const emptyOption = { caption: '', subcaption: '', type: 'image' };

export const AdminPage = () => {
  const [tournletData, setTournletData] = useState<any>({});
  const [options, setOptions] = useState<any>([{ ...emptyOption }]);
  const [mediaFiles, setMediaFiles] = useState<any>({});
  const [tournletMessage, setTournletMessage] = useState<string | null>(null);

  const addMoreOptions = () => setOptions([...options, { ...emptyOption }]);
  const inputChange = ({ target }) => {
    setTournletData({ ...tournletData, ...{ [target.name]: target.value } });
  };

  const optionInputChange = (index, target) => {
    let newOptions = [...options];
    newOptions[index] = { ...newOptions[index], ...{ [target.name]: target.value } };
    setOptions(newOptions);
  };

  const submit = async () => {
    setTournletMessage('Creating Tournlet');
    try {
      const url = await createTournlet(tournletData, options, mediaFiles);
      alert ('tournlet created!');
      setTournletMessage(url);
    } catch {
      alert ('there was an error creating the tournlet, try again');
      setTournletMessage('there was an error creating the tournlet, try again');
    }
  };

  const handleMediaFileSelection = (event, key) => {
    setMediaFiles({...mediaFiles, [key]: event.target.files[0]});
  }

  return (
    <div className="AdminPage">
      <form>
        <label>
          Title
          <input onChange={inputChange} name="title" type="text" />
        </label>
        <label>
          Description
          <input onChange={inputChange} name="description" type="text" />
        </label>
        <label>
          Cover image
          <input type="file" onChange={(event) => handleMediaFileSelection(event, 'cover')} />
        </label>
        <div className="options-container">
          {options.map((o, index) => (
            <div key={index} className="option-item">
              Option {index + 1}
              <label>
                Media
                <input type="file" onChange={(event) => handleMediaFileSelection(event, index)}/>
              </label>
              <label>
                Caption
                <input
                  name="caption"
                  value={options[index].caption}
                  onChange={({ target }) => optionInputChange(index, target)}
                  type="text"
                />
              </label>
              <label>
                Subcaption
                <input
                  name="subcaption"
                  onChange={({ target }) => optionInputChange(index, target)}
                  value={options[index].subcaption}
                  type="text"
                />
              </label>
              <label>
                Type:
                <select
                  value={options[index].type}
                  onChange={({ target }) => optionInputChange(index, target)}
                  name="type"
                >
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                </select>
              </label>
            </div>
          ))}
        </div>
        <button className="link" type="button" onClick={() => addMoreOptions()}>
          Add more options
        </button>
        <button className="primary-btn base-bg" type="button" onClick={() => submit()}>
          Create Tournlet
        </button>
        {
          tournletMessage && 
          <div className="message">
            {tournletMessage}
          </div>
        }
      </form>
    </div>
  );
};

export default AdminPage;
