import { getFirestoreInstance } from 'utils/firebase.utils';

const options = [
  {
    media: 'https://media1.tenor.com/images/b1196dca2b16992d1a2b595fdf822ae4/tenor.gif?itemid=4746884',
    caption: 'Castor Troy',
    subcaption: 'Hallelujah!',
    type: 'image',
  },
  {
    media:
      'https://img4.looper.com/img/gallery/the-most-paused-nicolas-cage-movie-moments/nicolas-cages-crazy-death-in-the-wicker-man-1595447851.jpg',
    caption: 'The Wicker Man',
    subcaption: 'Not the bees!!',
    type: 'image',
  },
  {
    media:
      'https://img1.looper.com/img/gallery/the-most-paused-nicolas-cage-movie-moments/press-pause-in-vampires-kiss-and-behold-the-face-of-insanity-1595447851.jpg',
    caption: "Vampire's Kiss",
    subcaption: "You don't say?",
    type: 'image',
  },
  {
    media: 'https://media1.tenor.com/images/fce0bd8495ce32ffd722ad2904759504/tenor.gif?itemid=5620240',
    caption: 'Con Air',
    subcaption: 'Finally some freedom',
    type: 'image',
  },
  {
    media: 'https://img1.looper.com/img/gallery/the-most-paused-nicolas-cage-movie-moments/intro-1595447851.jpg',
    caption: 'Ghost Rider',
    subcaption: 'Johnny Blaze',
    type: 'image',
  },
  {
    media: 'https://media1.tenor.com/images/6f65ab89760a556faec0310a88343fe9/tenor.gif?itemid=7806996',
    caption: 'Memphis',
    subcaption: 'The Low Ri-der',
    type: 'image',
  },
  {
    media: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVWMCwGxnn5uonJS4J8YyB6uV5J6bzsDSFNg&usqp=CAU',
    caption: 'Ross Cage',
    subcaption: 'Because why not?',
    type: 'image',
  },
  {
    media:
      'https://dam.smashmexico.com.mx/wp-content/uploads/2019/12/crisis-on-infinite-earths-superman-nicolas-cage-cover.jpg',
    caption: 'Supercage',
    subcaption: 'Imagine what could it have been',
    type: 'image',
  },
  {
    media: 'https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm',
    caption: 'This is a video',
    subcaption: "And it's looped!",
    type: 'video',
  },
  {
    media:
      "Sometimes people think I'm wearing a wig when I'm not wearing a wig, and then sometimes they think I'm not wearing a wig when I am wearing a wig.",
    caption: 'Are you wearing a wig Nic?',
    subcaption: "It's impossible to know",
    type: 'text',
  },
];

const optionStats = [
  {
    position: 1,
    percentage: 38,
  },
  {
    position: 2,
    percentage: 24,
  },
  {
    position: 3,
    percentage: 14,
  },
  {
    position: 4,
    percentage: 10,
  },
  {
    position: 5,
    percentage: 5,
  },
  {
    position: 6,
    percentage: 2,
  },
  {
    position: 7,
    percentage: 0,
  },
  {
    position: 8,
    percentage: 0,
  },
  {
    position: 9,
    percentage: 0,
  },
  {
    position: 10,
    percentage: 0,
  },
];

export const loadSeed = async () => {
  const db = getFirestoreInstance();
  let tournletRef = await db.collection('tournlets').add({
    title: 'Cage out!',
    coverImage: 'https://hipertextual.com/files/2016/11/tumblr_static_filename_640_v2.png?width=1200&enable=upscale',
    slug: 'cage-out',
    creationDate: '2021-01-17T14:07:40.761Z',
    likes: 8,
    plays: 23,
    description: 'Choose the best Nic Cage moment!',
    author: {
      id: 1,
      username: 'jgazzano',
      profileImg:
        'https://img1.looper.com/img/gallery/the-most-paused-nicolas-cage-movie-moments/press-pause-in-vampires-kiss-and-behold-the-face-of-insanity-1595447851.jpg',
      tournlets: 4,
    },
  });

  const optionIds: any[] = [];

  await Promise.all(
    options.map(async (o, index) => {
      const optionRef = await db.collection('tournlets').doc(tournletRef.id).collection('options').add(o);
      optionIds.push(optionRef.id);
    }),
  );

  tournletRef.set(
    { optionStats: optionIds.map((id, index) => ({ optionId: id, ...optionStats[index] })) },
    { merge: true },
  );
};;
