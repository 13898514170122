import './SocialBar.scss';
import { ReactComponent as LikeFilledIcon } from 'assets/icons/favorite-icon.svg';
import { ReactComponent as LikeBorderIcon } from 'assets/icons/favorite-border-icon.svg';
import React from 'react';
import ShareButton from 'components/ShareButton/ShareButton';

const SocialBar = () => {
  return (
    <div className="SocialBar">
      <ShareButton />
      <button className="light-icon">
        <LikeBorderIcon />
      </button>
      <div className="bottom">
        <a href="" className="pixki-anchor">
          <img src={`${process.env.PUBLIC_URL}/social-bar/pixki-light-vert-logo.svg`} alt="pixki logo" />
        </a>
      </div>
    </div>
  );
};

export default SocialBar;
