import { useEffect, useState } from 'react';
import './SocialAuthButton.scss';

type AuthService = 'facebook' | 'google' | 'twitter';

type ButtonStyle = 'light' | 'dark';

type SocialAuthButtonProps = {
  style?: ButtonStyle | null;
  onClick: Function;
  service: AuthService;
};

async function getBtnImage(service: AuthService, style?) {
  try {
    let image: any = null;
    switch (service) {
      case 'facebook':
        image = await require('../../../assets/icons/facebook/f_logo_RGB-Blue_100.png');
        break;
      case 'twitter':
        image = await require('../../../assets/icons/twitter/Twitter_Logo_Blue.svg');
        break;
      case 'google':
        image =
          (await style) === 'dark'
            ? require('../../../assets/icons/google/btn_google_dark_normal_ios.svg')
            : require('../../../assets/icons/google/btn_google_light_normal_ios.svg');
        break;
      default:
        break;
    }
    return image ? image.default : null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const SocialAuthButton = ({ service, style, onClick = () => {} }: SocialAuthButtonProps) => {
  const [btnImage, setBtnImage] = useState<any>(null);

  useEffect(() => {
    loadBtnImage(service, style);
  }, [service, style]);

  const loadBtnImage = async (service, style) => {
    setBtnImage(await getBtnImage(service, style));
  };

  return (
    <button onClick={() => onClick()} className={`SocialAuthButton ${style} ${service}`}>
      <img src={btnImage} alt={`${service} icon`} />
      Continue with&nbsp;<span className="service">{service}</span>
    </button>
  );
};

export default SocialAuthButton;
