import './StyleGallery.scss';
import React from 'react';
import {PaintBox} from "../../paint/PaintBox";

const StyleGallery = () => {

    PaintBox.applyPaint("px-purple", "panel1");
    PaintBox.applyPaint("green-glass", "panel2");
    PaintBox.applyPaint("sunrise", "panel3");
    PaintBox.applyPaint("rainbow", "panel4");

    PaintBox.applyPaint("space", "panel5");
    PaintBox.applyPaint("water", "panel6");
    PaintBox.applyPaint("rice-paper", "panel7");
    PaintBox.applyPaint("marble", "panel8");

    return (
        <div className="StyleGallery">
            <div className="style-row">
                <div className={"style-panel panel1"}>&nbsp;</div>
                <div className="spacer">&nbsp;</div>
                <div className={"style-panel panel2"}>&nbsp;</div>
                <div className="spacer">&nbsp;</div>
                <div className={"style-panel panel3"}>&nbsp;</div>
                <div className="spacer">&nbsp;</div>
                <div className={"style-panel panel4"}>&nbsp;</div>
            </div>
            <div className="spacer">&nbsp;</div>
            <div className="style-row">
                <div className={"style-panel panel5"}>&nbsp;</div>
                <div className="spacer">&nbsp;</div>
                <div className={"style-panel panel6"}>&nbsp;</div>
                <div className="spacer">&nbsp;</div>
                <div className={"style-panel panel7"}>&nbsp;</div>
                <div className="spacer">&nbsp;</div>
                <div className={"style-panel panel8"}>&nbsp;</div>
            </div>
        </div>
    );
}

export default StyleGallery;
