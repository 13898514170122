import {Paint} from "./Paint";

export class Color implements Paint {
    colorDef: string;

    constructor(color: string) {
        this.colorDef = color;
    }

    getCSSRule(): string {
        return `background-color: ${this.colorDef};`;
    }

    toString(): string {
        return this.colorDef;
    }
}