import {Paint} from "./Paint";
import {List} from "../kd";
import {Quantity} from "../kd/Quantity";

/**
 * A simple two stop gradient with rotation
 */
export class PImage implements Paint {
    imageName: string;
    tiled: boolean;

    // Set width or height to null to use the image's natural size
    width: Quantity | null;
    height: Quantity | null;

    constructor(imageName: string, tiled = false, width : Quantity | null = null,
                height: Quantity | null = null) {

        this.imageName = imageName;
        this.tiled = tiled;
        this.width = width;
        this.height = height;
    }

    getCSSRule(): string {
        let rule = `background-image: url("${process.env.PUBLIC_URL}/paint/images/${this.imageName}");`;
        if(this.tiled) {
            rule+=" background-repeat: repeat;";
        } else {
            rule+=" background-size: cover; background-position: center;"
        }

        if(this.width!=null || this.height!=null) {
            rule+=` background-size: ${(this.width) ? this.width.toString() : "auto"} ` +
                `${(this.height) ? this.height.toString() : "auto"};`;
        }

        return rule;
    }

    toString(): string {
        return this.getCSSRule().substring("background-image: ".length);
    }
}