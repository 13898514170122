import AdminPage from 'components/AdminPage/AdminPage';
import { Auth } from 'components/auth/Auth';
import Game from 'components/Game/Game';
import Landing from 'components/Landing/Landing';
import StyleGallery from "./components/StyleGallery/StyleGallery";

const routes = [
  {
    path: '/:username/:slug',
    component: Game,
  },
  {
    path: '/auth',
    component: Auth,
  },
  {
    path: '/styles',
    component: StyleGallery,
  },
  {
    path: '/admin',
    component: AdminPage,
  },
  {
    path: '/',
    component: Landing,
  },
];

export default routes;
