import './TournletCover.scss';
import 'react-bulma-components/lib/components/image';
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite-icon.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play-icon.svg';
import { TournletData } from '../Tournlet';
import { DateTime } from 'luxon';
import UserBadge from '../../shared/UserBadge/UserBadge';

type TournletCoverProps = {
  tournletData: TournletData;
  startPlaying: Function;
};

function relativeDate(isoDate: string): string {
  return DateTime.fromISO(isoDate)?.toLocal()?.toRelative()?.toString() || '';
}

const TournletCover = ({ tournletData, startPlaying }: TournletCoverProps) => {
  return (
    <div className="TournletCover">
      <div className="left has-ratio">
        <button className="image is-square" onClick={() => startPlaying()}>
          <img src={tournletData.coverImage} alt="" />
        </button>
        <button className="play-btn" onClick={() => startPlaying()}>
          <PlayIcon />
        </button>
      </div>

      <div className="right">
        <h1 className="headline-2 title">{tournletData.title}</h1>
        {tournletData.description && <h2 className="subtitle-2">{tournletData.description}</h2>}
        <div className="bottom">
          <div className="caption">{tournletData.entrants} Entrants</div>
          <div className="caption stats">
            <span className="likes">
              <FavoriteIcon />
              &nbsp;{tournletData.likes} likes
            </span>
            &nbsp;● {tournletData.plays} plays ● {relativeDate(tournletData.creationDate)}
          </div>
          <UserBadge user={tournletData.author} />
        </div>
      </div>
    </div>
  );
};

export default TournletCover;
