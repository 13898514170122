import useMobileDevice from 'hooks/useMobileDevice';
import './ForceLandscape.scss';

export const ForceLandscape = () => {
  const isMobile = useMobileDevice();

  return isMobile ? (
    <div className="ForceLandscape">
      <div className="content">
        <div className="rotate-img">
          <svg height="300" width="300" fill="#FFF" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <style></style>
            <path d="M96.3 88.8V53c0-3.2-2.7-6-6-6H21.6c-3.3 0-6 2.7-6 6v35.9c0 3.2 2.7 6 6 6h68.7c3.4-.1 6-2.8 6-6.1zm-74.7 3c-1.6 0-3-1.4-3-3V53c0-1.6 1.3-3 3-3h68.7c1.6 0 3 1.4 3 3v35.9c0 1.6-1.3 3-3 3H21.6z" />
            <path d="M27.6 53v35.9h56.8V53H27.6zm53.8 3v29.9H30.6V56h50.8z" />
            <path d="M12.6 82.9h-3c-1.6 0-3-1.3-3-3V11.2c0-1.6 1.4-3 3-3h35.9c1.6 0 3 1.3 3 3V44h3V11.2c0-3.3-2.7-6-6-6H9.7c-3.2 0-6 2.7-6 6v68.7c0 3.3 2.7 6 6 6h3v-3z" />
            <path d="M12.6 70.9V20.1h29.9V44h3V17.1H9.7v56.8h2.9zM90.7 32.7l-3.9 5.2c-2.5-13.2-12.8-23.6-26-26.1L65.9 8l-1.8-2.4-9.7 7 7 9.7 2.4-1.8-4.3-6c12.3 2.1 22.1 11.9 24.3 24.2L78 34.3l-1.8 2.4 9.5 7.2 7.2-9.5-2.2-1.7z" />
          </svg>
        </div>
        <p>Please rotate your device</p>
        <p>This page is best viewed in Landscape </p>
      </div>
    </div>
  ) : (
    <></>
  );
};
