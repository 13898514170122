import logo from '../../assets/pixki_logo_light.svg';
import './Landing.scss';
import { Link } from 'react-router-dom';
import React from 'react';
import Header from 'components/Header/Header';

const Landing = () => {

  return (
    <div className="Landing">
      <Header></Header>
      <img src={logo} className="landing-img" alt="Pixki" />
      <Link to="gmorris/male-solo-musicians-of-the-70-s" className="alt-btn big">
        Click to begin!
      </Link>
    </div>
  );
};

export default Landing;
